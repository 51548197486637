<template lang="pug">
    div#privacy.d-flex.flex-column.align-items-center
        p
            strong Protecting your privacy is extremely important to Boon Supply, and the purpose of this policy is to help you understand how we go about doing that. This statement covers all of our information handling practices, for both information collected online and offline, and is published here for your benefit.
        p &nbsp;
        h2
            strong Types of Information We Collect
        p We collect two basic types of information &ndash;&nbsp;personal information&nbsp;and&nbsp;anonymous information&nbsp;&ndash; and we may use personal and anonymous information to create a third type of information,&nbsp;aggregate information. We collect the following categories of information:
        ul
            li Registration information you provide when you create an account, including your first name and surname, physical address, contact phone number, gender, date of birth, email address, username and password.
            li Transaction information you provide when you request information or purchase a product or service from us, whether on our sites or through our applications, including your email address, postal address, telephone number and payment information.
            li Information you provide in&nbsp;public forums&nbsp;on our sites and&nbsp;applications.
            li Information sent either one-to-one or within a limited group using our message, chat, post or similar functionality, where we are permitted by law to collect this information.
            li Information you provide to us when you use our sites and applications, our applications on third-party sites or platforms such as social networking sites, or link your profile on a third-party site or platform with your registration account.
            li Location information when you visit our sites or use our applications, including location information either provided by a mobile device interacting with one of our sites or applications (including through beacon technologies), or associated with your&nbsp;IP address, where we are permitted by law to process this information.
            li Usage, viewing and technical data, including your device identifier or IP address, when you visit our sites, use our applications on third-party sites or platforms or open emails we send
        p &nbsp;
        h2
            strong How We Collect Your Information
        p We collect information you provide to us when you register on the site to use the site and when you purchase products, services or seek information from us, participate in&nbsp;public forums&nbsp;or other activities on our site and&nbsp;applications, respond to customer surveys, or otherwise interact with us using one or more devices. Please keep in mind that when you provide information to us on a third-party site or platform (for example, via our applications), the information you provide may be separately collected by the third-party site or platform. The information we collect is covered by this privacy policy and the information the third-party site or platform collects is subject to the third-party site or platform&rsquo;s privacy practices. Privacy choices you have made on the third-party site or platform will not apply to our use of the information we have collected directly through our applications.
        p We collect information through technology, such as cookies, Flash cookies and Web beacons, including when you visit our sites and applications or use our applications on third-party sites or platforms using one or more devices. You may choose to disable cookies as a means to avoid sharing same.
        p We acquire information from other trusted sources to update or supplement the information you provided or we collected automatically. Local law may require that you authorize the third party to share your information with us before we can acquire it.
        p &nbsp;
        h2
            strong How We Use Your Information
        p Boon Supply may use your personal information for the following purposes:
        ul
            li Provide you with the products and services you request or sign up for on our site.
            li Communicate with you about your account or transactions with us and send you information about features on our sites, and&nbsp;applications&nbsp;or changes to our policies.
            li
                | Consistent with local law and choices and controls that may be available to you we may use information collected from you, or from devices associated with you, to:
                ul
                    li Send you offers and promotions for our products and services or third-party products and services.
                    li Personalize content and experiences.
                    li Provide you with advertising based on your activity on our sites and applications and on third-party sites and applications.
        ul
            li Optimize or improve our products, services and operations.
            li Detect, investigate and prevent activities that may violate our policies or be illegal.
        h2
            strong
        h2
            strong Sharing Your Information with Third Parties other than Charities
        p We will share your personal information with charities you agree to associate with on the site.&nbsp; We will not share your&nbsp;personal information with other commercial companies except in limited circumstances, including:
        ul
            li
                | When you allow us to share your personal information with another company, such as:
                ul
                    li Electing to share your personal information with carefully selected companies so that they can send you offers and promotions about their products and services
                    li Directing us to share your personal information with third-party sites or platforms, such as social networking sites.
        p
            em
                u Please note
            | : that once we share your personal information with another company, the information received by the other company becomes subject to the other company&rsquo;s privacy practices.
        ul
            li When companies perform services on our behalf, like package delivery and customer service; however, these companies are prohibited from using your personal information for purposes other than those requested by us or required by law.
            li When we share personal information with third parties in connection with the sale of a business, to enforce our Terms of Use or rules, to ensure the safety and security of our guests and third parties, to protect our rights and property and the rights and property of our guests and third parties, to comply with legal process or in other cases if we believe in good faith that disclosure is required by law.
        p &nbsp;
        h2
            strong Google
        p While we do not sell, trade, or otherwise transfer to outside parties your personal information unless we provide users with advance notice, this does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it&apos;s release is appropriate to comply with the law, enforce our site policies, or protect ours or others&apos; rights, property or safety.
        p However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
        p Third-party links:&nbsp; We do not include or offer third-party products or services on our website other than those services necessary for the proper operation of our website and we do not link to third party websites.
        p &nbsp;
        h2
            strong Your Controls and Choices Regarding Your Personal Information
        p We provide you the ability to exercise certain controls and choices regarding our collection, use and sharing of your personal information. In accordance with local law, your controls and choices may include:
        ul
            li You may correct, update and delete your registration account.
            li You may change your choices for subscriptions, newsletters and alerts.
            li You may choose whether to receive from us offers and promotions for our products and services, or products and services that we think may be of interest to you.
            li You may choose whether we share your personal information&nbsp;with other companies so they can send you offers and promotions about their products and services.
            li
                | You may choose whether to receive targeted advertising from many ad networks, data exchanges, marketing analytics and other service providers by visiting&nbsp;
                a(href='http://www.aboutads.info/choices') the Digital Advertising Alliance.
            li You may request access to the personal information we hold about you and that we amend or delete it.
        p You may exercise your controls and choices, or request access to your personal information, by contacting us in the &ldquo;contact section&rdquo; below, or following instructions provided in communications sent to you. Please be aware that, if you do not allow us to collect personal information from you, we may not be able to deliver certain products and services to you, and some of our services may not be able to take account of your interests and preferences. If you have questions regarding the specific personal information about you that we process or retain, please contact us:
        p &nbsp;
        h2
            strong Children&rsquo;s Online Privacy
        p We recognize the need to provide further privacy protections with respect to personal information we may collect from children on our site and&nbsp;applications. Some of the features on our site and applications are age limited so that they are not available for use by children, and we do not knowingly collect personal information from children in connection with those features.
        p This Children&rsquo;s Online Privacy Policy explains our information collection, disclosure, and parental consent practices with respect to information provided by children under the age of 13 (&ldquo;child&rdquo; or &ldquo;children&rdquo;), and uses terms that are defined elsewhere in this Privacy Policy.&nbsp; This policy is in accordance with the U.S. Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;), and outlines our practices in the United States regarding children&rsquo;s personal information.
        p We do not typically collect information from children age 13 and younger. When we intend to collect personal information from children, we take additional steps to protect children&rsquo;s privacy, including:
        ul
            li Notifying parents about our information practices with regard to children, including the types of personal information we may collect from children, the uses to which we may put that information, and whether and with whom we may share that information.
            li In accordance with applicable law, obtaining consent from parents for the collection of personal information from their children, or for sending information about our products and services directly to their children.
            li Limiting our collection of personal information from children to no more than is reasonably necessary to participate in an online activity.
            li Giving parents access or the ability to request access to personal information we have collected from their children and the ability to request that the personal information be changed or deleted.
        p &nbsp;
        h2
            strong Data Security and Integrity
        p The security, integrity and confidentiality of your information are extremely important to us. We have implemented technical, administrative and physical security measures that are designed to protect user&rsquo;s personal information from unauthorized access, disclosure, use and modification. From time to time, we review our security procedures to consider appropriate new technology and methods. Please be aware though that, despite our best efforts, no security measures are perfect or impenetrable.&nbsp;We will retain your personal information&nbsp;for the length of time needed to fulfill the purposes outlined in this privacy policy unless a longer retention period is required or permitted by law.
        p &nbsp;
        h2
            strong Data Transfers &ndash; Security
        p We operate only in the United States and Canada.&nbsp; We may transfer your personal information to individual companies as needed to provide the services of this site and to serve the needs of users.&nbsp; Such third parties are located in the United States for the purposes described in this privacy policy. Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of your personal information. We also apply the substantive requirements of Canada in terms of collection and use of personal information.
        p &nbsp;
        h2
            strong Changes to this Privacy Policy
        p From time to time, we may change this privacy policy to accommodate new technologies, industry practices, regulatory requirements or for other purposes. We will attempt to provide notice to you if these changes are material and, where required by applicable law, we will obtain your consent.&nbsp; You should revisit and review this Privacy Policy from time to time to ensure you are familiar with the most up-to-date policy.
        p &nbsp;
        h2
            strong Questions/Comments/Communications
        p
            | If you have a comment or question about this Privacy Policy, please contact us at 
            a(href='mailto:policy@boonsupply.com') policy@boonsupply.com
            | . Our sites and&nbsp;applications&nbsp;may contain links to other sites not owned or controlled by us and we are not responsible for the privacy practices of those sites. We encourage you to be aware when you leave our sites or applications and to read the privacy policies of other sites that may collect your personal information.
        p &nbsp;
        h2
            strong Your California Privacy Rights
        p Notice&nbsp;to California Residents:&nbsp;If you are a California resident, you may have certain additional rights. California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your personal information by us to third parties for the third parties&rsquo; direct marketing purposes. California Business and Professions Code Section 22581 permits registered users who are minors to request and obtain deletion of certain posted content.
        p
            | To make such a request, please send an email to 
            a(href='mailto:policy@boonsupply.com') policy@boonsupply.com
            |  or write us:
        p Boon Supply
        p 2443 Fillmore Street #380-17309
        p San Francisco, CA 94115
</template>
    
<script>
export default {
    name: "PrivacyPolicy"
};
</script>
    
<style scoped lang="less">
#privacy {
    padding: 40px 10px;
    background: white;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

h1,
h2,
h3 {
    text-align: left;
    max-width: 100%;
    word-break: break-word;
}

p,
li {
    text-align: left;
    padding: 10px 0;
}

@media (max-width: 767px) {
    h2 {
        font-size: 24px;
    }
}
</style>
    