<template lang="pug">
div#app
  b-container.mx-0.px-0#main(fluid)
    #router-content
      router-view
</template>

<script>
//import your components here
export default {
  name: "App",
  data() {
    return {
      prevHeight: 0
    };
  }
};
</script>

<style lang="less">
@import "./assets/styles/fonts.css";

html {
  margin-left: calc(100vw - 100%);
}

.fade-enter-active,
.fade-enter-active * {
  transition-duration: 1s !important;
  transition-timing-function: ease !important;
  visibility: visible !important;
}

.fade-leave-active,
.fade-leave-active * {
  transition-duration: 0.3s !important;
  transition-timing-function: ease !important;
  visibility: visible !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: height;
  overflow: hidden;

  &:not(#home)> :first-child {
    transform: translateY(0%);
  }
}

.fade-enter,
.fade-leave-active {
  &:not(#home)> :first-child {
    transform: translateY(2.25%);
  }
}

.fade-enter-active,
.fade-leave-active,
.fade-enter-active *,
.fade-leave-active * {
  transition-property: opacity;
  opacity: 1 !important;
  visibility: visible !important;
}

.fade-enter,
.fade-leave-active,
.fade-enter *,
.fade-leave-active * {
  transition-property: opacity;
  opacity: 0 !important;
  visibility: hidden !important;
}

html,
body,
#app,
#main {
  height: 100%;
  min-height: 100%;
  transition: all 0.25s ease;
}

body {
  background-image: url("assets/images/bg-desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  margin: 0;
}

#router-content {
  height: 100%;
}

p,
li,
a:link,
a:visited,
a:hover,
a:active {
  color: #0A2240;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 680px;
  margin-bottom: 0;
}

li {
  text-align: left;
}

@media (max-width: 767px) {
  body {
    background-image: url("assets/images/bg-mobile.jpg");
  }

  p {
    font-size: 16px;
    max-width: 294px;
  }
}
</style>
