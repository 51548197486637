<template lang="pug">
div#home.d-flex.flex-column.align-items-center
  #content-top.d-flex.flex-column.align-items-center.justify-content-center
    img.logotype(src="../assets/images/logotype.png")
    img.thankyou(src="../assets/images/thank-you.png")
    p.flex-row.align-items-center.font-weight-500 Since 2010, you’ve helped us raise over $104,000,000 for schools, teams, and other causes that make our world a better place.
    br
    p.flex-row.align-items-center As an effect of the pandemic on traditional school fundraising, we're ceasing our direct fundraising operations. We sincerely thank you for your support over the years.
    br
    p.flex-row.align-items-center 
      span.d-inline If you have any outstanding questions, please contact 
      span.d-inline &nbsp;
      a.d-inline(href="mailto:customercare@boonsupply.com" target="_blank" rel="noopener noreferrer") customercare@boonsupply.com
      span.d-inline .
  #content-bottom.d-flex.flex-column.align-items-center.justify-content-center
    p COPYRIGHT BOON SUPPLY. ALL RIGHTS RESERVED
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped lang="less">
#home {
  height: 100%;
  padding: 0;

  #content-top {
    flex: 2;
  }

  #content-bottom {
    width: 100%;
    height: 41px;
    min-height: 41px;
    background: #F8F5F0;

    >p {
      color: #D5D3CE;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
  }
}

.font-weight-500 {
  font-weight: 500 !important;
}

.logotype {
  width: 214px;
  flex-shrink: 0;
  margin-bottom: 36px;
}

.thankyou {
  width: 406px;
  margin-bottom: 22px;
}

@media (max-width: 767px) {
  #content-top {
    padding-top: 16vh;
  }

  .logotype {
    width: 220px;
  }

  .thankyou {
    width: 307px;
  }
}
</style>
